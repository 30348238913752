/* You can add global styles to this file, and also import other style files */

:root {
  --primary: #05bf04;
  --secondary: #f2994a;
  --accent: 4, 191, 4;
  --success: #27ae60;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font: 400 0.8rem "Mulish", sans-serif;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    color: #0D0F11;
}

a,
a:hover {
  text-decoration: none;
}

.root {
  position: relative;
  background: transparent;
  min-height: 100%;
  width: 100%;
}

.root--in-frame {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  width: 100%;
  overflow-y: auto;
}

#threedsChallengeRedirect {
	display: flex;
}

#challengeFrame {
	border: 0;
	margin: 50px auto;
}

//bootstrap reboot
.bs-rebootAbcXyz {

  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  @media (prefers-reduced-motion: no-preference) {
    :root {
      scroll-behavior: smooth;
    }
  }

  hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: 0.25;
  }

  ol,
  ul {
    padding-left: 2rem;
  }

  ul {
    list-style: none;
  }

  dl,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0;
  }

  a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
  }

  b,
  strong {
    font-weight: bolder;
  }

  .small,
  small {
    font-size: 0.875em;
  }

  a:not([href]):not([class]),
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }

  label {
    display: inline-block;
  }

  button:focus:not(:focus-visible) {
    outline: 0;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button,
  select {
    text-transform: none;
  }

  [role="button"] {
    cursor: pointer;
  }

  select {
    word-wrap: normal;
  }

  select:disabled {
    opacity: 1;
  }

  [list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
    display: none !important;
  }

  [type="button"],
  [type="reset"],
  [type="submit"],
  button {
    -webkit-appearance: button;
  }

  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled),
  button:not(:disabled) {
    cursor: pointer;
  }

  ::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  textarea {
    resize: vertical;
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-fields-wrapper,
  ::-webkit-datetime-edit-hour-field,
  ::-webkit-datetime-edit-minute,
  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-text,
  ::-webkit-datetime-edit-year-field {
    padding: 0;
  }

  ::-webkit-inner-spin-button {
    height: auto;
  }

  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: textfield;
  }

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }

  ::file-selector-button {
    font: inherit;
    -webkit-appearance: button;
  }

  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }

  .list-inline {
    padding-left: 0;
    list-style: none;
  }

  .list-inline-item {
    display: inline-block;
  }

  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
  }

  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid var(--bs-border-color);
    border-radius: 0.375rem;
    max-width: 100%;
    height: auto;
  }

  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
  }

  @media (min-width: 576px) {

    .container,
    .container-sm {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {

    .container,
    .container-md,
    .container-sm {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width: 1140px;
    }
  }

  @media (min-width: 1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      max-width: 1320px;
    }
  }

  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }

  .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }

  .col {
    flex: 1 0 0%;
  }

  .row-cols-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  @media (min-width: 576px) {
    .col-sm {
      flex: 1 0 0%;
    }

    .row-cols-sm-auto>* {
      flex: 0 0 auto;
      width: auto;
    }

    .row-cols-sm-1>* {
      flex: 0 0 auto;
      width: 100%;
    }

    .row-cols-sm-2>* {
      flex: 0 0 auto;
      width: 50%;
    }

    .row-cols-sm-3>* {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }

    .row-cols-sm-4>* {
      flex: 0 0 auto;
      width: 25%;
    }

    .row-cols-sm-5>* {
      flex: 0 0 auto;
      width: 20%;
    }

    .row-cols-sm-6>* {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }

    .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
    }

    .col-sm-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }

    .col-sm-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }

    .col-sm-3 {
      flex: 0 0 auto;
      width: 25%;
    }

    .col-sm-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }

    .col-sm-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }

    .col-sm-6 {
      flex: 0 0 auto;
      width: 50%;
    }

    .col-sm-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }

    .col-sm-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }

    .col-sm-9 {
      flex: 0 0 auto;
      width: 75%;
    }

    .col-sm-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }

    .col-sm-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }

    .col-sm-12 {
      flex: 0 0 auto;
      width: 100%;
    }
    
  }

  @media (min-width: 768px) {
    .col-md {
      flex: 1 0 0%;
    }

    .row-cols-md-auto>* {
      flex: 0 0 auto;
      width: auto;
    }

    .row-cols-md-1>* {
      flex: 0 0 auto;
      width: 100%;
    }

    .row-cols-md-2>* {
      flex: 0 0 auto;
      width: 50%;
    }

    .row-cols-md-3>* {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }

    .row-cols-md-4>* {
      flex: 0 0 auto;
      width: 25%;
    }

    .row-cols-md-5>* {
      flex: 0 0 auto;
      width: 20%;
    }

    .row-cols-md-6>* {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }

    .col-md-auto {
      flex: 0 0 auto;
      width: auto;
    }

    .col-md-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }

    .col-md-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }

    .col-md-3 {
      flex: 0 0 auto;
      width: 25%;
    }

    .col-md-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }

    .col-md-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }

    .col-md-6 {
      flex: 0 0 auto;
      width: 50%;
    }

    .col-md-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }

    .col-md-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }

    .col-md-9 {
      flex: 0 0 auto;
      width: 75%;
    }

    .col-md-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }

    .col-md-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }

    .col-md-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  }

  @media (min-width: 992px) {
    .col-lg {
      flex: 1 0 0%;
    }

    .row-cols-lg-auto>* {
      flex: 0 0 auto;
      width: auto;
    }

    .row-cols-lg-1>* {
      flex: 0 0 auto;
      width: 100%;
    }

    .row-cols-lg-2>* {
      flex: 0 0 auto;
      width: 50%;
    }

    .row-cols-lg-3>* {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }

    .row-cols-lg-4>* {
      flex: 0 0 auto;
      width: 25%;
    }

    .row-cols-lg-5>* {
      flex: 0 0 auto;
      width: 20%;
    }

    .row-cols-lg-6>* {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }

    .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
    }

    .col-lg-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }

    .col-lg-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }

    .col-lg-3 {
      flex: 0 0 auto;
      width: 25%;
    }

    .col-lg-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }

    .col-lg-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }

    .col-lg-6 {
      flex: 0 0 auto;
      width: 50%;
    }

    .col-lg-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }

    .col-lg-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }

    .col-lg-9 {
      flex: 0 0 auto;
      width: 75%;
    }

    .col-lg-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }

    .col-lg-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }

    .col-lg-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  }

  @media (min-width: 1200px) {
    .col-xl {
      flex: 1 0 0%;
    }

    .row-cols-xl-auto>* {
      flex: 0 0 auto;
      width: auto;
    }

    .row-cols-xl-1>* {
      flex: 0 0 auto;
      width: 100%;
    }

    .row-cols-xl-2>* {
      flex: 0 0 auto;
      width: 50%;
    }

    .row-cols-xl-3>* {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }

    .row-cols-xl-4>* {
      flex: 0 0 auto;
      width: 25%;
    }

    .row-cols-xl-5>* {
      flex: 0 0 auto;
      width: 20%;
    }

    .row-cols-xl-6>* {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }

    .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
    }

    .col-xl-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }

    .col-xl-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }

    .col-xl-3 {
      flex: 0 0 auto;
      width: 25%;
    }

    .col-xl-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }

    .col-xl-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }

    .col-xl-6 {
      flex: 0 0 auto;
      width: 50%;
    }

    .col-xl-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }

    .col-xl-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }

    .col-xl-9 {
      flex: 0 0 auto;
      width: 75%;
    }

    .col-xl-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }

    .col-xl-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }

    .col-xl-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  }

  @media (min-width: 1400px) {
    .col-xxl {
      flex: 1 0 0%;
    }

    .row-cols-xxl-auto>* {
      flex: 0 0 auto;
      width: auto;
    }

    .row-cols-xxl-1>* {
      flex: 0 0 auto;
      width: 100%;
    }

    .row-cols-xxl-2>* {
      flex: 0 0 auto;
      width: 50%;
    }

    .row-cols-xxl-3>* {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }

    .row-cols-xxl-4>* {
      flex: 0 0 auto;
      width: 25%;
    }

    .row-cols-xxl-5>* {
      flex: 0 0 auto;
      width: 20%;
    }

    .row-cols-xxl-6>* {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }

    .col-xxl-auto {
      flex: 0 0 auto;
      width: auto;
    }

    .col-xxl-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }

    .col-xxl-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }

    .col-xxl-3 {
      flex: 0 0 auto;
      width: 25%;
    }

    .col-xxl-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }

    .col-xxl-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }

    .col-xxl-6 {
      flex: 0 0 auto;
      width: 50%;
    }

    .col-xxl-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }

    .col-xxl-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }

    .col-xxl-9 {
      flex: 0 0 auto;
      width: 75%;
    }

    .col-xxl-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }

    .col-xxl-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }

    .col-xxl-12 {
      flex: 0 0 auto;
      width: 100%;
    }

  }

  .form-text {
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #6c757d;
  }

  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #198754;
  }

  .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    color: #fff;
    background-color: rgba(25, 135, 84, 0.9);
    border-radius: 0.375rem;
  }

  .is-valid~.valid-feedback,
  .is-valid~.valid-tooltip,
  .was-validated :valid~.valid-feedback,
  .was-validated :valid~.valid-tooltip {
    display: block;
  }

  .form-select.is-valid,
  .was-validated .form-select:valid {
    border-color: #198754;
  }

  .form-select.is-valid:not([multiple]):not([size]),
  .form-select.is-valid:not([multiple])[size="1"],
  .was-validated .form-select:valid:not([multiple]):not([size]),
  .was-validated .form-select:valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"),
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .form-select.is-valid:focus,
  .was-validated .form-select:valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
  }

  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
  }

  .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0.375rem;
  }

  .is-invalid~.invalid-feedback,
  .is-invalid~.invalid-tooltip,
  .was-validated :invalid~.invalid-feedback,
  .was-validated :invalid~.invalid-tooltip {
    display: block;
  }

  .form-select.is-invalid,
  .was-validated .form-select:invalid {
    border-color: #dc3545;
  }

  .form-select.is-invalid:not([multiple]):not([size]),
  .form-select.is-invalid:not([multiple])[size="1"],
  .was-validated .form-select:invalid:not([multiple]):not([size]),
  .was-validated .form-select:invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"),
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .form-select.is-invalid:focus,
  .was-validated .form-select:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }

  .fade {
    transition: opacity 0.15s linear;
  }

  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none;
    }
  }

  .fade:not(.show) {
    opacity: 0;
  }

  .collapse:not(.show) {
    display: none;
  }

  .collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }

  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none;
    }
  }

  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease;
  }

  @media (prefers-reduced-motion: reduce) {
    .collapsing.collapse-horizontal {
      transition: none;
    }
  }

  .nav {
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-link-color);
    --bs-nav-link-hover-color: var(--bs-link-hover-color);
    --bs-nav-link-disabled-color: #6c757d;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: var(--bs-nav-link-color);
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none;
    }
  }

  .nav-link:focus,
  .nav-link:hover {
    color: var(--bs-nav-link-hover-color);
  }

  .nav-link.disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default;
  }

  .nav-tabs {
    --bs-nav-tabs-border-width: 1px;
    --bs-nav-tabs-border-color: #dee2e6;
    --bs-nav-tabs-border-radius: 0.375rem;
    --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
    --bs-nav-tabs-link-active-color: #495057;
    --bs-nav-tabs-link-active-bg: #fff;
    --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
    border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
  }

  .nav-tabs .nav-link {
    margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
    background: 0 0;
    border: var(--bs-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius);
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    isolation: isolate;
    border-color: var(--bs-nav-tabs-link-hover-border-color);
  }

  .nav-tabs .nav-link.disabled,
  .nav-tabs .nav-link:disabled {
    color: var(--bs-nav-link-disabled-color);
    background-color: transparent;
    border-color: transparent;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color);
  }

  .nav-tabs .dropdown-menu {
    margin-top: calc(var(--bs-nav-tabs-border-width) * -1);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .nav-pills {
    --bs-nav-pills-border-radius: 0.375rem;
    --bs-nav-pills-link-active-color: #fff;
    --bs-nav-pills-link-active-bg: #0d6efd;
  }

  .nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: var(--bs-nav-pills-border-radius);
  }

  .nav-pills .nav-link:disabled {
    color: var(--bs-nav-link-disabled-color);
    background-color: transparent;
    border-color: transparent;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg);
  }

  .nav-fill .nav-item,
  .nav-fill>.nav-link {
    flex: 1 1 auto;
    text-align: center;
  }

  .nav-justified .nav-item,
  .nav-justified>.nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }

  .nav-fill .nav-item .nav-link,
  .nav-justified .nav-item .nav-link {
    width: 100%;
  }

  .tab-content>.tab-pane {
    display: none;
  }

  .tab-content>.active {
    display: block;
  }

  .card {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
    --bs-card-title-spacer-y: 0.5rem;
    --bs-card-border-width: 1px;
    --bs-card-border-color: var(--bs-border-color-translucent);
    --bs-card-border-radius: 0.375rem;
    --bs-card-box-shadow: ;
    --bs-card-inner-border-radius: calc(0.375rem - 1px);
    --bs-card-cap-padding-y: 0.5rem;
    --bs-card-cap-padding-x: 1rem;
    --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
    --bs-card-cap-color: ;
    --bs-card-height: ;
    --bs-card-color: ;
    --bs-card-bg: #fff;
    --bs-card-img-overlay-padding: 1rem;
    --bs-card-group-margin: 0.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    height: var(--bs-card-height);
    word-wrap: break-word;
    background-color: var(--bs-card-bg);
    background-clip: border-box;
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-radius: var(--bs-card-border-radius);
  }

  .card>hr {
    margin-right: 0;
    margin-left: 0;
  }

  .card>.list-group {
    border-top: inherit;
    border-bottom: inherit;
  }

  .card>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: var(--bs-card-inner-border-radius);
    border-top-right-radius: var(--bs-card-inner-border-radius);
  }

  .card>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: var(--bs-card-inner-border-radius);
    border-bottom-left-radius: var(--bs-card-inner-border-radius);
  }

  .card-body {
    flex: 1 1 auto;
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    color: var(--bs-card-color);
  }

  .alert {
    --bs-alert-bg: transparent;
    --bs-alert-padding-x: 1rem;
    --bs-alert-padding-y: 1rem;
    --bs-alert-margin-bottom: 1rem;
    --bs-alert-color: inherit;
    --bs-alert-border-color: transparent;
    --bs-alert-border: 1px solid var(--bs-alert-border-color);
    --bs-alert-border-radius: 0.375rem;
    position: relative;
    padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
    margin-bottom: var(--bs-alert-margin-bottom);
    color: var(--bs-alert-color);
    background-color: var(--bs-alert-bg);
    border: var(--bs-alert-border);
    border-radius: var(--bs-alert-border-radius);
  }

  .progress {
    --bs-progress-height: 1rem;
    --bs-progress-font-size: 0.75rem;
    --bs-progress-bg: #e9ecef;
    --bs-progress-border-radius: 0.375rem;
    --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    --bs-progress-bar-color: #fff;
    --bs-progress-bar-bg: #0d6efd;
    --bs-progress-bar-transition: width 0.6s ease;
    display: flex;
    height: var(--bs-progress-height);
    overflow: hidden;
    font-size: var(--bs-progress-font-size);
    background-color: var(--bs-progress-bg);
    border-radius: var(--bs-progress-border-radius);
  }

  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: var(--bs-progress-bar-bg);
    transition: var(--bs-progress-bar-transition);
  }

  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none;
    }
  }

  .progress-bar-striped {
    background-image: linear-gradient(45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent);
    background-size: var(--bs-progress-height) var(--bs-progress-height);
  }

  .progress-bar-animated {
    -webkit-animation: 1s linear infinite progress-bar-stripes;
    animation: 1s linear infinite progress-bar-stripes;
  }

  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
      animation: none;
    }
  }

  .modal {
    --bs-modal-zindex: 1055;
    --bs-modal-width: 500px;
    --bs-modal-padding: 1rem;
    --bs-modal-margin: 0.5rem;
    --bs-modal-color: ;
    --bs-modal-bg: #fff;
    --bs-modal-border-color: var(--bs-border-color-translucent);
    --bs-modal-border-width: 1px;
    --bs-modal-border-radius: 0.5rem;
    --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    --bs-modal-inner-border-radius: calc(0.5rem - 1px);
    --bs-modal-header-padding-x: 1rem;
    --bs-modal-header-padding-y: 1rem;
    --bs-modal-header-padding: 1rem 1rem;
    --bs-modal-header-border-color: var(--bs-border-color);
    --bs-modal-header-border-width: 1px;
    --bs-modal-title-line-height: 1.5;
    --bs-modal-footer-gap: 0.5rem;
    --bs-modal-footer-bg: ;
    --bs-modal-footer-border-color: var(--bs-border-color);
    --bs-modal-footer-border-width: 1px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-modal-zindex);
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }

  .modal-dialog {
    position: relative;
    width: auto;
    margin: var(--bs-modal-margin);
    pointer-events: none;
  }

  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
  }

  @media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      transition: none;
    }
  }

  .modal.show .modal-dialog {
    transform: none;
  }

  .modal.modal-static .modal-dialog {
    transform: scale(1.02);
  }

  .modal-dialog-scrollable {
    height: calc(100% - var(--bs-modal-margin) * 2);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden;
  }

  .modal-dialog-scrollable .modal-body {
    overflow-y: auto;
  }

  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
  }

  .modal-backdrop {
    --bs-backdrop-zindex: 1050;
    --bs-backdrop-bg: #000;
    --bs-backdrop-opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--bs-backdrop-zindex);
    width: 100vw;
    height: 100vh;
    background-color: var(--bs-backdrop-bg);
  }

  .modal-backdrop.fade {
    opacity: 0;
  }

  .modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity);
  }

  .modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-modal-header-padding);
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius);
  }

  .modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
    margin: calc(var(--bs-modal-header-padding-y) * -0.5) calc(var(--bs-modal-header-padding-x) * -0.5) calc(var(--bs-modal-header-padding-y) * -0.5) auto;
  }

  .modal-title {
    margin-bottom: 0;
    line-height: var(--bs-modal-title-line-height);
  }

  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: var(--bs-modal-padding);
  }

  .modal-footer {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
    background-color: var(--bs-modal-footer-bg);
    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  }

  .modal-footer>* {
    margin: calc(var(--bs-modal-footer-gap) * 0.5);
  }

  @media (min-width: 576px) {
    .modal {
      --bs-modal-margin: 1.75rem;
      --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }

    .modal-dialog {
      max-width: var(--bs-modal-width);
      margin-right: auto;
      margin-left: auto;
    }

    .modal-sm {
      --bs-modal-width: 300px;
    }
  }

  @media (min-width: 992px) {

    .modal-lg,
    .modal-xl {
      --bs-modal-width: 800px;
    }
  }

  @media (min-width: 1200px) {
    .modal-xl {
      --bs-modal-width: 1140px;
    }
  }

  .clearfix::after {
    display: block;
    clear: both;
    content: "";
  }

  .align-baseline {
    vertical-align: baseline !important;
  }

  .align-top {
    vertical-align: top !important;
  }

  .border {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }

  .border-top {
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }

  .border-bottom {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }

  .border-start {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }

  .w-25 {
    width: 25% !important;
  }

  .w-50 {
    width: 50% !important;
  }

  .w-75 {
    width: 75% !important;
  }

  .w-100 {
    width: 100% !important;
  }

  .w-auto {
    width: auto !important;
  }

  .flex-row {
    flex-direction: row !important;
  }

  .flex-column {
    flex-direction: column !important;
  }

  .flex-wrap {
    flex-wrap: wrap !important;
  }

  .flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .justify-content-start {
    justify-content: flex-start !important;
  }

  .justify-content-end {
    justify-content: flex-end !important;
  }

  .justify-content-center {
    justify-content: center !important;
  }

  .justify-content-between {
    justify-content: space-between !important;
  }

  .justify-content-around {
    justify-content: space-around !important;
  }

  .justify-content-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-start {
    align-items: flex-start !important;
  }

  .align-items-end {
    align-items: flex-end !important;
  }

  .align-items-center {
    align-items: center !important;
  }

  .align-items-baseline {
    align-items: baseline !important;
  }

  .align-items-stretch {
    align-items: stretch !important;
  }

  .align-content-start {
    align-content: flex-start !important;
  }

  .align-content-end {
    align-content: flex-end !important;
  }

  .align-content-center {
    align-content: center !important;
  }

  .align-content-between {
    align-content: space-between !important;
  }

  .align-content-around {
    align-content: space-around !important;
  }

  .align-content-stretch {
    align-content: stretch !important;
  }

  .align-self-auto {
    align-self: auto !important;
  }

  .align-self-start {
    align-self: flex-start !important;
  }

  .align-self-end {
    align-self: flex-end !important;
  }

  .align-self-center {
    align-self: center !important;
  }

  .align-self-baseline {
    align-self: baseline !important;
  }

  .align-self-stretch {
    align-self: stretch !important;
  }

  .order-first {
    order: -1 !important;
  }

  .order-0 {
    order: 0 !important;
  }

  .order-1 {
    order: 1 !important;
  }

  .order-2 {
    order: 2 !important;
  }

  .order-3 {
    order: 3 !important;
  }

  .order-4 {
    order: 4 !important;
  }

  .order-5 {
    order: 5 !important;
  }

  .order-last {
    order: 6 !important;
  }

  .m-0 {
    margin: 0 !important;
  }

  .m-1 {
    margin: 0.25rem !important;
  }

  .m-2 {
    margin: 0.5rem !important;
  }

  .m-3 {
    margin: 1rem !important;
  }

  .m-4 {
    margin: 1.5rem !important;
  }

  .m-5 {
    margin: 3rem !important;
  }

  .m-auto {
    margin: auto !important;
  }

  .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-0 {
    margin-top: 0 !important;
  }

  .mt-1 {
    margin-top: 0.25rem !important;
  }

  .mt-2 {
    margin-top: 0.5rem !important;
  }

  .mt-3 {
    margin-top: 1rem !important;
  }

  .mt-4 {
    margin-top: 1.5rem !important;
  }

  .mt-5 {
    margin-top: 3rem !important;
  }

  .mt-auto {
    margin-top: auto !important;
  }

  .me-0 {
    margin-right: 0 !important;
  }

  .me-1 {
    margin-right: 0.25rem !important;
  }

  .me-2 {
    margin-right: 0.5rem !important;
  }

  .me-3 {
    margin-right: 1rem !important;
  }

  .me-4 {
    margin-right: 1.5rem !important;
  }

  .me-5 {
    margin-right: 3rem !important;
  }

  .me-auto {
    margin-right: auto !important;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-3 {
    margin-bottom: 1rem !important;
  }

  .mb-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-5 {
    margin-bottom: 3rem !important;
  }

  .mb-auto {
    margin-bottom: auto !important;
  }

  .ms-0 {
    margin-left: 0 !important;
  }

  .ms-1 {
    margin-left: 0.25rem !important;
  }

  .ms-2 {
    margin-left: 0.5rem !important;
  }

  .ms-3 {
    margin-left: 1rem !important;
  }

  .ms-4 {
    margin-left: 1.5rem !important;
  }

  .ms-5 {
    margin-left: 3rem !important;
  }

  .ms-auto {
    margin-left: auto !important;
  }

  .p-0 {
    padding: 0 !important;
  }

  .p-1 {
    padding: 0.25rem !important;
  }

  .p-2 {
    padding: 0.5rem !important;
  }

  .p-3 {
    padding: 1rem !important;
  }

  .p-4 {
    padding: 1.5rem !important;
  }

  .p-5 {
    padding: 3rem !important;
  }

  .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-0 {
    padding-top: 0 !important;
  }

  .pt-1 {
    padding-top: 0.25rem !important;
  }

  .pt-2 {
    padding-top: 0.5rem !important;
  }

  .pt-3 {
    padding-top: 1rem !important;
  }

  .pt-4 {
    padding-top: 1.5rem !important;
  }

  .pt-5 {
    padding-top: 3rem !important;
  }

  .pe-0 {
    padding-right: 0 !important;
  }

  .pe-1 {
    padding-right: 0.25rem !important;
  }

  .pe-2 {
    padding-right: 0.5rem !important;
  }

  .pe-3 {
    padding-right: 1rem !important;
  }

  .pe-4 {
    padding-right: 1.5rem !important;
  }

  .pe-5 {
    padding-right: 3rem !important;
  }

  .pb-0 {
    padding-bottom: 0 !important;
  }

  .pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-3 {
    padding-bottom: 1rem !important;
  }

  .pb-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-5 {
    padding-bottom: 3rem !important;
  }

  .ps-0 {
    padding-left: 0 !important;
  }

  .ps-1 {
    padding-left: 0.25rem !important;
  }

  .ps-2 {
    padding-left: 0.5rem !important;
  }

  .ps-3 {
    padding-left: 1rem !important;
  }

  .ps-4 {
    padding-left: 1.5rem !important;
  }

  .ps-5 {
    padding-left: 3rem !important;
  }

  .gap-0 {
    gap: 0 !important;
  }

  .gap-1 {
    gap: 0.25rem !important;
  }

  .gap-2 {
    gap: 0.5rem !important;
  }

  .gap-3 {
    gap: 1rem !important;
  }

  .gap-4 {
    gap: 1.5rem !important;
  }

  .gap-5 {
    gap: 3rem !important;
  }

  .font-monospace {
    font-family: var(--bs-font-monospace) !important;
  }

  .fs-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  .fs-2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }

  .fs-3 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }

  .fs-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }

  .fs-5 {
    font-size: 1.25rem !important;
  }

  .fs-6 {
    font-size: 1rem !important;
  }

  .fst-italic {
    font-style: italic !important;
  }

  .fst-normal {
    font-style: normal !important;
  }

  .fw-light {
    font-weight: 300 !important;
  }

  .fw-lighter {
    font-weight: lighter !important;
  }

  .fw-normal {
    font-weight: 400 !important;
  }

  .fw-bold {
    font-weight: 700 !important;
  }

  .fw-semibold {
    font-weight: 600 !important;
  }

  .fw-bolder {
    font-weight: bolder !important;
  }

  .lh-1 {
    line-height: 1 !important;
  }

  .lh-sm {
    line-height: 1.25 !important;
  }

  .lh-base {
    line-height: 1.5 !important;
  }

  .lh-lg {
    line-height: 2 !important;
  }

  .text-start {
    text-align: left !important;
  }

  .text-end {
    text-align: right !important;
  }

  .text-center {
    text-align: center !important;
  }

  .text-decoration-none {
    text-decoration: none !important;
  }

  .text-decoration-underline {
    text-decoration: underline !important;
  }

  .text-decoration-line-through {
    text-decoration: line-through !important;
  }

  .text-lowercase {
    text-transform: lowercase !important;
  }

  .text-uppercase {
    text-transform: uppercase !important;
  }

  .text-capitalize {
    text-transform: capitalize !important;
  }

  .text-wrap {
    white-space: normal !important;
  }

  .text-nowrap {
    white-space: nowrap !important;
  }

  .text-break {
    word-wrap: break-word !important;
    word-break: break-word !important;
  }

  .text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }

  .text-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }

  .text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }

  .text-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
  }

  .text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }

  .text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }

  .text-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  }

  .text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }

  .text-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  }

  .text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  }

  .text-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  }

  .bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb),
        var(--bs-bg-opacity)) !important;
  }

  .bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb),
        var(--bs-bg-opacity)) !important;
  }

  .bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb),
        var(--bs-bg-opacity)) !important;
  }

  .bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  }

  .bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb),
        var(--bs-bg-opacity)) !important;
  }

  .bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
  }

  .bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  }

  .bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }

  .bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
  }

  .bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
  }

  .bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb),
        var(--bs-bg-opacity)) !important;
  }

  .bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
  }

  .rounded-circle {
    border-radius: 50% !important;
  }

  .visible {
    visibility: visible !important;
  }

  .invisible {
    visibility: hidden !important;
  }
}

//omni-wrapper
.omni-popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.omni-form-control {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 12px;
  font-size: 13px;
  line-height: 1.5;
  color: #9ea3ae;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.012);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.012);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.omni-form-control:focus {
  background-color: #fff;
  border-color: var(--primary);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px var(--primary);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px var(--primary);
}

.omni-form-control.payattitude:focus {
  border-color: transparent;
  --webkit-box-shadow: none;
  box-shadow: none
}
.omni-form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.omni-form-control:-ms-input-placeholder {
  color: #999;
}

.omni-form-control::-webkit-input-placeholder {
  color: #999;
}

.omni-form-control.payattitude {
  border-color: transparent;
}
.group-input {
  position: relative;
  width: 100%;
}

.ngx-otp-input {
  width: 45px !important;
  height: 45px !important;
}

.ngx-otp-input-container {
  justify-content: center;
}

.ngx-otp-input-container input {
  border: 1px solid #d3d6da !important;
  border-radius: 8px !important;
}

.ngx-otp-input-container input:focus {
  border-color: var(--secondary) !important;
}

.omni-input[type="radio"]:checked,
.omni-input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.omni-input[type="radio"]:checked+label,
.omni-input[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}

.omni-input[type="radio"]:checked+label:before,
.omni-input[type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 20px;
  top: -20px;
  width: 18px;
  height: 18px;
  border: 1px solid var(--primary);
  border-radius: 50%;
  background: #fff;
}

.omni-input[type="radio"]:checked+label:after,
.omni-input[type="radio"]:not(:checked)+label:after {
  content: "";
  width: 9.5px;
  height: 10px;
  background: var(--primary);
  position: absolute;
  top: -16px;
  left: 24px;
  border-radius: 40%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.omni-input[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.omni-input[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.omni-input[type="checkbox"]:not(:checked),
.omni-input[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}

.omni-input[type="checkbox"]:not(:checked)+label,
.omni-input[type="checkbox"]:checked+label {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
}

.omni-input[type="checkbox"]:not(:checked)+label:before,
.omni-input[type="checkbox"]:checked+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  border: 1px solid #ddd;
  background: #ffffff;
  border-radius: 4px;
}

.omni-input[type="checkbox"]:not(:checked)+label:after,
.omni-input[type="checkbox"]:checked+label:after {
  content: "";
  width: 11px;
  height: 11px;
  border: 1px solid var(--primary);
  background: var(--primary);
  position: absolute;
  top: 58%;
  margin-top: -7px;
  left: 2.3px;
  border-radius: 3px;
  color: var(--primary);
}

.omni-input[type="checkbox"]:not(:checked)+label:after {
  opacity: 0;
  transform: scale(0);
}

.omni-input[type="checkbox"]:checked+label:after {
  opacity: 1;
  transform: scale(1);
}

.omni-input[type="checkbox"][data-indeterminate]+label:after,
.omni-input[type="checkbox"][data-indeterminate]+label:after {
  content: "";
  left: 2px;
  opacity: 1;
  transform: scale(1);
}

.omni-input[type="checkbox"]:checked:focus+label:before,
.omni-input[type="checkbox"]:not(:checked):focus+label:before {
  border: 1px solid var(--primary);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(var(--accent), 0.25);
}

.omni-input>label:hover:before {
  border: 1px solid var(--primary) !important;
}

.omni-btn {
  color: #fff;
  width: 100%;
  height: 47px;
  font-weight: 500;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 1em 2em;
  font-size: 13px;
  line-height: 1.428571429;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.omni-btn:focus,
.omni-btn.focus,
.omni-btn:active:focus,
.omni-btn:active.focus,
.omni-btn.active:focus,
.omni-btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
  border-color: var(--primary);
}

.omni-btn:hover,
.omni-btn:focus,
.omni-btn.focus {
  color: #333;
  text-decoration: none;
  border-color: var(--primary);
}

.omni-btn:active,
.omni-btn.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: var(--primary);
}

.omni-btn.disabled,
.omni-btn[disabled],
fieldset[disabled] .omni-btn {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: var(--primary);
}

a.omni-btn.disabled,
fieldset[disabled] a.omni-btn {
  pointer-events: none;
}

.omni-btn.omni-btn-primary {
  color: #fff !important;
  background-color: var(--primary);
}

.omni-btn.omni-btn-transparent {
  background-color: transparent;
  border: none;
  color: #333333;
  font-size: 13px;
}

.omni-btn:focus {
  outline: none !important;
  border-color: var(--primary);
}

@-webkit-keyframes animation-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes animation-rotate {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes animation-rotate {
  100% {
    -o-transform: rotate(360deg);
  }
}

@keyframes animation-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.omni-btn-loading {
  min-height: 38px;
  position: relative;
  cursor: not-allowed;
  pointer-events: none;
}

.omni-btn-loading:before {
  margin: -13px 0 0 -13px;
  width: 24px;
  height: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  -webkit-border-radius: 24px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 24px;
  -moz-background-clip: padding;
  border-radius: 24px;
  background-clip: padding-box;
  border: rgba(255, 255, 255, 0.25) 2px solid;
  border-top-color: #fff;
  -webkit-animation: animation-rotate 750ms linear infinite;
  -moz-animation: animation-rotate 750ms linear infinite;
  -o-animation: animation-rotate 750ms linear infinite;
  animation: animation-rotate 750ms linear infinite;
}

.omni-btn-loading span,
.omni-btn-loading:hover span,
.omni-btn-loading:active span {
  color: transparent;
  text-shadow: none;
}

.omni-btn-block {
  display: block;
  width: 100%;
}

.omni-btn-block+.omni-btn-block {
  margin-top: 5px;
}

input[type="submit"].omni-btn-block,
input[type="reset"].omni-btn-block,
input[type="button"].omni-btn-block {
  width: 100%;
}

.omni-checkout-alert {
  margin: 70px 0 50px 0;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  .lh-2 {
    line-height: 2;
  }
}

.omni-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #81909d;
  margin-bottom: 7px !important;
}

.verification-loader {
  margin: 30px 0 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 200px;

  .omni-spinner-text {
    font-weight: 300;
    font-size: 14px;
    line-height: 1.8;
    /* identical to box height, or 18px */
  
    letter-spacing: -0.03em;
  
    color: #000000;
    display: block;
    margin-bottom: 30px;
    text-align: center;
  }

  .omni-spinner-border {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-border-width: 0.25em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: omni-spinner-border;
  }

  .omni-spinner-border,
  .omni-spinner-grow {
    display: inline-block;
    width: var(--bs-spinner-width);
    height: var(--bs-spinner-height);
    vertical-align: var(--bs-spinner-vertical-align);
    border-radius: 50%;
    -webkit-animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
    animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  }

  @-webkit-keyframes omni-spinner-border {
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes omni-spinner-border {
    to {
      transform: rotate(360deg);
    }
  }

  .omni-spinner-border {
    border: var(--bs-spinner-border-width) solid rgba(var(--accent), 0.5);
    border-right-color: var(--primary);
  }

  @-webkit-keyframes omni-spinner-grow {
    0% {
      transform: scale(0);
    }

    50% {
      opacity: 1;
      transform: none;
    }
  }

  @keyframes omni-spinner-grow {
    0% {
      transform: scale(0);
    }

    50% {
      opacity: 1;
      transform: none;
    }
  }

  .omni-spinner-grow {
    --bs-spinner-width: 2rem;
    --bs-spinner-height: 2rem;
    --bs-spinner-vertical-align: -0.125em;
    --bs-spinner-animation-speed: 0.75s;
    --bs-spinner-animation-name: omni-spinner-grow;
    background-color: currentcolor;
    opacity: 0;
  }

  .omni-spinner-grow-sm {
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem;
  }

  @media (prefers-reduced-motion: reduce) {

    .omni-spinner-border,
    .omni-spinner-grow {
      --bs-spinner-animation-speed: 1.5s;
    }
  }
}

//helper styles
.fz-10 {
  font-size: 10px;
}

.fz-12 {
  font-size: 12px !important;
}

.fz-13 {
  font-size: 13px;
}

.fz-14 {
  font-size: 14px;
}

.fz-16 {
  font-size: 16px;
}

.fz-18 {
  font-size: 18px;
}

.fw-sbold {
  font-weight: 600;
}

.fw-bold {
  font-weight: bold;
}

.badge-warn {
  background: #fff9eb;
  border-radius: 16px;
  color: #f2994a;
  font-weight: 500;
  padding: 6px 8px;
}

.badge-success {
  background: rgba(39, 174, 96, 0.14);
  border-radius: 11px;
  color: var(--success);
  padding: 8px 10px;
}

.badge-error {
  background: rgba(235, 87, 87, 0.13);
  border-radius: 11px;
  color: #eb5757;
  padding: 8px 10px;
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
 .la-ball-spin-clockwise,
 .la-ball-spin-clockwise > div {
     position: relative;
     -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
             box-sizing: border-box;
 }
 .la-ball-spin-clockwise {
     display: block;
     font-size: 0;
     color: #fff;
 }
 .la-ball-spin-clockwise.la-dark {
     color: #333;
 }
 .la-ball-spin-clockwise > div {
     display: inline-block;
     float: none;
     background-color: currentColor;
     border: 0 solid currentColor;
 }
 .la-ball-spin-clockwise {
     width: 32px;
     height: 32px;
 }
 .la-ball-spin-clockwise > div {
     position: absolute;
     top: 50%;
     left: 50%;
     width: 8px;
     height: 8px;
     margin-top: -4px;
     margin-left: -4px;
     border-radius: 100%;
     -webkit-animation: ball-spin-clockwise 1s infinite ease-in-out;
        -moz-animation: ball-spin-clockwise 1s infinite ease-in-out;
          -o-animation: ball-spin-clockwise 1s infinite ease-in-out;
             animation: ball-spin-clockwise 1s infinite ease-in-out;
 }
 .la-ball-spin-clockwise > div:nth-child(1) {
     top: 5%;
     left: 50%;
     -webkit-animation-delay: -.875s;
        -moz-animation-delay: -.875s;
          -o-animation-delay: -.875s;
             animation-delay: -.875s;
 }
 .la-ball-spin-clockwise > div:nth-child(2) {
     top: 18.1801948466%;
     left: 81.8198051534%;
     -webkit-animation-delay: -.75s;
        -moz-animation-delay: -.75s;
          -o-animation-delay: -.75s;
             animation-delay: -.75s;
 }
 .la-ball-spin-clockwise > div:nth-child(3) {
     top: 50%;
     left: 95%;
     -webkit-animation-delay: -.625s;
        -moz-animation-delay: -.625s;
          -o-animation-delay: -.625s;
             animation-delay: -.625s;
 }
 .la-ball-spin-clockwise > div:nth-child(4) {
     top: 81.8198051534%;
     left: 81.8198051534%;
     -webkit-animation-delay: -.5s;
        -moz-animation-delay: -.5s;
          -o-animation-delay: -.5s;
             animation-delay: -.5s;
 }
 .la-ball-spin-clockwise > div:nth-child(5) {
     top: 94.9999999966%;
     left: 50.0000000005%;
     -webkit-animation-delay: -.375s;
        -moz-animation-delay: -.375s;
          -o-animation-delay: -.375s;
             animation-delay: -.375s;
 }
 .la-ball-spin-clockwise > div:nth-child(6) {
     top: 81.8198046966%;
     left: 18.1801949248%;
     -webkit-animation-delay: -.25s;
        -moz-animation-delay: -.25s;
          -o-animation-delay: -.25s;
             animation-delay: -.25s;
 }
 .la-ball-spin-clockwise > div:nth-child(7) {
     top: 49.9999750815%;
     left: 5.0000051215%;
     -webkit-animation-delay: -.125s;
        -moz-animation-delay: -.125s;
          -o-animation-delay: -.125s;
             animation-delay: -.125s;
 }
 .la-ball-spin-clockwise > div:nth-child(8) {
     top: 18.179464974%;
     left: 18.1803700518%;
     -webkit-animation-delay: 0s;
        -moz-animation-delay: 0s;
          -o-animation-delay: 0s;
             animation-delay: 0s;
 }
 .la-ball-spin-clockwise.la-sm {
     width: 16px;
     height: 16px;
 }
 .la-ball-spin-clockwise.la-sm > div {
     width: 4px;
     height: 4px;
     margin-top: -2px;
     margin-left: -2px;
 }
 .la-ball-spin-clockwise.la-2x {
     width: 64px;
     height: 64px;
 }
 .la-ball-spin-clockwise.la-2x > div {
     width: 16px;
     height: 16px;
     margin-top: -8px;
     margin-left: -8px;
 }
 .la-ball-spin-clockwise.la-3x {
     width: 96px;
     height: 96px;
 }
 .la-ball-spin-clockwise.la-3x > div {
     width: 24px;
     height: 24px;
     margin-top: -12px;
     margin-left: -12px;
 }
 /*
  * Animation
  */
 @-webkit-keyframes ball-spin-clockwise {
     0%,
     100% {
         opacity: 1;
         -webkit-transform: scale(1);
                 transform: scale(1);
     }
     20% {
         opacity: 1;
     }
     80% {
         opacity: 0;
         -webkit-transform: scale(0);
                 transform: scale(0);
     }
 }
 @-moz-keyframes ball-spin-clockwise {
     0%,
     100% {
         opacity: 1;
         -moz-transform: scale(1);
              transform: scale(1);
     }
     20% {
         opacity: 1;
     }
     80% {
         opacity: 0;
         -moz-transform: scale(0);
              transform: scale(0);
     }
 }
 @-o-keyframes ball-spin-clockwise {
     0%,
     100% {
         opacity: 1;
         -o-transform: scale(1);
            transform: scale(1);
     }
     20% {
         opacity: 1;
     }
     80% {
         opacity: 0;
         -o-transform: scale(0);
            transform: scale(0);
     }
 }
 @keyframes ball-spin-clockwise {
     0%,
     100% {
         opacity: 1;
         -webkit-transform: scale(1);
            -moz-transform: scale(1);
              -o-transform: scale(1);
                 transform: scale(1);
     }
     20% {
         opacity: 1;
     }
     80% {
         opacity: 0;
         -webkit-transform: scale(0);
            -moz-transform: scale(0);
              -o-transform: scale(0);
                 transform: scale(0);
     }
 }